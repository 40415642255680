<template>
  <div>
    <a-row class="a-center">
      <a-col class="avatar" :span="24">
        <a-avatar :size="100" icon="user" />
      </a-col>
      <a-col class="name" :span="24">
        <span v-if="tempCustomer.person_type == 'Pessoa Jurídica'">
          {{ tempCustomer.trading_name }}
        </span>
        <span v-else>
          {{ tempCustomer.first_name }}
          {{ tempCustomer.last_name }}
        </span>
      </a-col>
      <a-col class="id" :span="24"> ID {{ tempCustomer.id }} </a-col>

      <a-col class="rate" :span="24">
        <a-rate v-model="customerRate" @change="rateCustomer" />
      </a-col>

      <a-col class="created" :span="24">
        {{ tempCustomer.user.first_name }} {{ tempCustomer.user.last_name }}
        <a-divider type="vertical" /> desde
        {{ formatDateTime(tempCustomer.created) }}
      </a-col>
    </a-row>
    <a-row class="wallet" type="flex" justify="space-between" align="middle">
      <a-col class="ico-name" :span="12">
        <img
          class="mr-5"
          src="@/assets/images/dashboard/customers/wallet-viajar.png"
          alt="wallet-ico"
          width="22"
        />
        Viajar Wallet
      </a-col>
      <a-col class="points a-right" :span="12">
        0 pontos
      </a-col>
    </a-row>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  props: {
    customers: Object,
    tempCustomer: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      customerRate: 0,
    };
  },
  mounted() {
    setTimeout(() => {
      this.customerRate =
        this.tempCustomer.rate != undefined
          ? parseInt(this.tempCustomer.rate)
          : 0;
    }, 500);
  },
  methods: {
    rateCustomer(val) {
      console.log(val);

      let valuesToUpdate = {};

      valuesToUpdate.id = this.tempCustomer.id;
      valuesToUpdate.rate = val;
      valuesToUpdate.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.tempCustomer.rate = val;

      this.$http
        .post("/customer/update-multi-meta", valuesToUpdate)
        .then(({ data }) => {
          data;
          this.$message.success("Cliente avaliado com sucesso!");

          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempCustomer.id,
            module: "customer",
            action: "rate-customer",
            description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> avaliou o cliente <font class="id">ID ${this.tempCustomer.id}</font> com ${this.tempCustomer.rate} estrelas.`,
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        })
        .finally(() => {
          this.loadingSubmitCustomer = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.wallet
  font-size: 11px
  margin: 20px 0 0 0
  .points
    font-weight: 600
.created
  font-size: 9px
  color: #aaa

.id
  color: #aaa
  margin-bottom: 10px
.name
  text-transform: uppercase
  padding: 0 10px
  font-size: 16px
  font-weight: 600
  margin: 10px 0 3px
  line-height: 1
.avatar
  margin-top: -70px
</style>
